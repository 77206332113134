import { chartTooltip } from '@/utils'
import { formatPayment } from '@/constants/config'
export const claimsProcessedChartOptions = {
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ]
  },
  tooltips: chartTooltip
}

export const accuracyChartOptions = {
  legend: {
    position: 'top',
    labels: {
      padding: 5,
      usePointStyle: false,
      fontSize: 12
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ]
  },
  tooltips: chartTooltip
}

export const profitabilityChartOptions = {
  legend: {
    position: 'top',
    labels: {
      padding: 5,
      usePointStyle: false,
      fontSize: 12
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        id: 'A',
        position: 'left',
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: -2000,
          suggestedMax: 2000,
          padding: 20,
          callback: function (value, index, values) {
            return formatPayment(value)
          }
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ]
  },
  tooltips: chartTooltip
}

// data tables
export const weeklyDataFields = [
  {
    key: 'week_name',
    label: 'Round'
  },
  {
    key: 'processed',
    label: '# Processed',
    sortable: true
  },
  {
    key: 'passed',
    label: '# Passed',
    sortable: true
  },
  {
    key: 'failed',
    label: '# Failed',
    sortable: true
  },
  {
    key: 'incorrect_name',
    label: '# Wrong Name'
  },
  {
    key: 'incorrect_policy_type',
    label: '# Wrong Policy Type'
  },
  {
    key: 'incorrect_approval_code',
    label: '# Wrong Approval Code'
  },
  {
    key: 'incorrect_decision',
    label: '# Wrong Decision'
  },
  {
    key: 'incorrect_payment_amount',
    label: '# Wrong Payment Amount'
  },
  {
    key: 'pass',
    label: 'Pass %',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  },
  {
    key: 'bonuses',
    label: 'Bonuses',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'additional_cost',
    label: 'Additional Costs (WIP, Purchases)',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'total_revenue',
    label: 'Total Revenue',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'total_cost',
    label: 'Total Cost',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'total_profit',
    label: 'Total Profit',
    formatter: (value) => {
      return formatPayment(value)
    }
  }
]

export const yesterdayDataFields = [
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'processed',
    label: '# Processed',
    sortable: true
  },
  {
    key: 'passed',
    label: '# Passed',
    sortable: true
  },
  {
    key: 'failed',
    label: '# Failed',
    sortable: true
  },
  {
    key: 'incorrect_name',
    label: '# Wrong Name'
  },
  {
    key: 'incorrect_policy_type',
    label: '# Wrong Policy Type'
  },
  {
    key: 'incorrect_approval_code',
    label: '# Wrong Approval Code'
  },
  {
    key: 'incorrect_decision',
    label: '# Wrong Decision'
  },
  {
    key: 'incorrect_payment_amount',
    label: '# Wrong Payment Amount'
  },
  {
    key: 'pass',
    label: 'Pass %',
    formatter: (value) => {
      return formatRatePercentage(value * 100, 2)
    }
  },
  {
    key: 'total_revenue',
    label: 'Total Revenue',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'total_cost',
    label: 'Total Cost',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'total_profit',
    label: 'Total Profit',
    formatter: (value) => {
      return formatPayment(value)
    }
  }
]

// admin - management
export const userDataFields = [
  {
    key: 'avatar',
    label: 'User Name',
    sortable: false,
    thClass: 'w-25',
    tdClass: 'align-middle'
  },
  {
    key: 'email',
    label: 'User Email',
    sortable: true,
    thClass: 'w-25',
    tdClass: 'align-middle'
  },
  {
    key: 'team_name',
    label: 'Team Name',
    sortable: true,
    thClass: 'w-25',
    tdClass: 'align-middle'
  },
  {
    key: 'role',
    label: 'User Role',
    sortable: true,
    thClass: 'w-20',
    tdClass: 'align-middle'
  },
  {
    key: 'actions',
    label: 'Actions',
    tdClass: 'align-middle action-column'
  }
]

export const userFilterFields = ['team_name', 'role', 'avatar', 'email']

export const teamDataFields = [
  {
    key: 'avatar',
    label: 'Team Name',
    sortable: false,
    thClass: 'w-90',
    tdClass: 'align-middle'
  },
  {
    key: 'actions',
    label: 'Actions',
    thClass: 'w-5',
    tdClass: 'align-middle action-column'
  }
]

// admin - score centre
export const teamWeekProcessedDataFields = [
  {
    key: 'team_name',
    label: 'Team Name',
    sortable: true,
    tdClass: 'align-middle'
  },
  {
    key: 'week1_processed',
    label: 'Week 1 Processed',
    sortable: true,
    tdClass: 'align-middle'
  },
  {
    key: 'week1_pass',
    label: 'Week 1 Pass %',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  },
  {
    key: 'week1_profit',
    label: 'R1 Profit',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'week2_processed',
    label: 'Week 2 Processed',
    sortable: true,
    tdClass: 'align-middle'
  },
  {
    key: 'week2_pass',
    label: 'Week 2 Pass %',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  },
  {
    key: 'week2_profit',
    label: 'R2 Profit',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'week3_processed',
    label: 'Week 3 Processed',
    sortable: true,
    tdClass: 'align-middle'
  },
  {
    key: 'week3_pass',
    label: 'Week 3 Pass %',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  },
  {
    key: 'week3_profit',
    label: 'R3 Profit',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'week4_processed',
    label: 'Week 4 Processed',
    sortable: true,
    tdClass: 'align-middle'
  },
  {
    key: 'week4_pass',
    label: 'Week 4 Pass %',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  },
  {
    key: 'week4_profit',
    label: 'R4 Profit',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'total_profit',
    label: 'Total',
    sortable: true,
    tdClass: 'align-middle',
    variant: 'dark',
    formatter: (value) => {
      return formatPayment(value)
    }
  }
]

export const teamWeekProfitDataFields = [
  {
    key: 'team_name',
    label: 'Team Name',
    sortable: true,
    tdClass: 'align-middle'
  },
  {
    key: 'week1_profit',
    label: 'R1 Profit',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'week2_profit',
    label: 'R2 Profit',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'week3_profit',
    label: 'R3 Profit',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'week4_profit',
    label: 'R4 Profit',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatPayment(value)
    }
  },
  {
    key: 'total_profit',
    label: 'Total',
    sortable: true,
    tdClass: 'align-middle',
    variant: 'dark',
    formatter: (value) => {
      return formatPayment(value)
    }
  }
]

export const teamWeekPassDataFields = [
  {
    key: 'team_name',
    label: 'Team Name',
    sortable: true,
    tdClass: 'align-middle'
  },
  {
    key: 'week1_pass',
    label: 'Week 1 Pass %',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  },
  {
    key: 'week2_pass',
    label: 'Week 2 Pass %',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  },
  {
    key: 'week3_pass',
    label: 'Week 3 Pass %',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  },
  {
    key: 'week4_pass',
    label: 'Week 4 Pass %',
    sortable: true,
    tdClass: 'align-middle',
    formatter: (value) => {
      return formatRatePercentage(value, 2)
    }
  }
]

export const weeklyProcessedChartOptions = {
  legend: {
    position: 'bottom',
    labels: {
      padding: 20,
      usePointStyle: false,
      fontSize: 12
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ]
  },
  tooltips: chartTooltip
}

export const weeklyPassedChartOptions = {
  legend: {
    position: 'bottom',
    labels: {
      padding: 20,
      usePointStyle: false,
      fontSize: 12
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ]
  },
  tooltips: {
    backgroundColor: chartTooltip.backgroundColor,
    titleFontColor: chartTooltip.titleFontColor,
    borderColor: chartTooltip.borderColor,
    borderWidth: chartTooltip.borderWidth,
    bodyFontColor: chartTooltip.bodyFontColor,
    bodySpacing: chartTooltip.bodySpacing,
    xPadding: chartTooltip.xPadding,
    yPadding: chartTooltip.yPadding,
    cornerRadius: chartTooltip.cornerRadius,
    callbacks: {
      label: function (tooltipItem, data) {
        return Number(tooltipItem.yLabel).toFixed(2)
      }
    }
  }
}

export const weeklyProfitabilityChartOptions = {
  legend: {
    position: 'bottom',
    labels: {
      padding: 20,
      usePointStyle: false,
      fontSize: 12
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ]
  },
  tooltips: {
    backgroundColor: chartTooltip.backgroundColor,
    titleFontColor: chartTooltip.titleFontColor,
    borderColor: chartTooltip.borderColor,
    borderWidth: chartTooltip.borderWidth,
    bodyFontColor: chartTooltip.bodyFontColor,
    bodySpacing: chartTooltip.bodySpacing,
    xPadding: chartTooltip.xPadding,
    yPadding: chartTooltip.yPadding,
    cornerRadius: chartTooltip.cornerRadius,
    callbacks: {
      label: function (tooltipItem, data) {
        return formatPayment(Number(tooltipItem.yLabel))
      }
    }
  }
}

// audit log
export const auditLogFields = [
  // {
  //   key: 'user_avatar',
  //   label: 'Avatar',
  //   sortable: true,
  //   sortDirection: 'desc',
  //   tdClass: 'align-middle'
  // },
  {
    key: 'agent_name',
    label: 'Agent Name',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'week_name',
    label: 'Week',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'day_name',
    label: 'Day',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'claim_status',
    label: 'Status',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'customer_first_name',
    label: 'First Name',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'customer_last_name',
    label: 'Last Name',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'policy_no',
    label: 'Policy Number',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'policy_type',
    label: 'Policy Type',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'claim_decision',
    label: 'Claim Decision',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'eligible_claim_amount',
    label: 'Eligible Claim Amount',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'excess',
    label: 'Excess',
    sortable: true,
    sortDirection: 'desc',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  },
  {
    key: 'action',
    label: '',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center'
  }
]

// static
export const userRoles = {
  options: [
    // { id: 1, name: "Super Admin"},
    // { id: 2, name: "Admin"},
    { id: 3, name: 'Team Leader' },
    { id: 4, name: 'Team Member' }
  ]
}

// functions
function formatRatePercentage(value, places) {
  return value ? Number(value.toFixed(places)) + '%' : '0%'
}
